import React from 'react';
import Login from './login';
import PlatformSync from './pages/PlatformSync';
import SetPassword from './pages/SetPassword';
import Channels from './pages/channels';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Products } from './pages/ProductsList/Products.tsx';
import { Home } from './pages/Home.tsx';
import { ProductDetail } from './pages/ProductDetail/ProductDetail.tsx';
import { useNavigate } from 'react-router-dom';
import { setNavigate } from './services/api'

const Root = () => {
  const navigate = useNavigate();
  setNavigate(navigate);

  return (
    <Routes>
      <Route path="/" exact element={<Login />} />
      <Route path='/set-password' element={<SetPassword />} />
      <Route path="/products" exact element={<Products />} />
      <Route path="/product/:id" element={<ProductDetail />} />
      <Route path='/sync' element={<PlatformSync />} />
      <Route path='/home' element={<Home />} />
      <Route path='/channels' element={<Channels />} />
    </Routes>
  );
}

const App = () => {
  return (
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  )
}

export default App;
