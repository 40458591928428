import s from './Navbar.module.css'
import logo from "../../assets/monogram.png";
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/user';
import TypeAheadFilter from '../TypeAheadFilter';
import PageviewIcon from '@mui/icons-material/Pageview';
import { useState } from 'react';

const Navbar = (props) => {
  const navigate = useNavigate()
  const { user } = useUser();
  const [enableSearch, setEnableSearch] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  return (
    <div className={`${s.header} ${props.activeMenu === '/products' && enableSearch && s.Navbar_height}`}>
      <div className={`${s.navbar} ${props.activeMenu === '/products' && enableSearch && s.Navbar_navbar_padding}`}>
        <div className={s.navbar__logo} onClick={() => navigate('/home')}>
          <div className={s.logo}>
            <img src={logo} alt="Logo" />
          </div>
        </div>
        <div className={s.navbar__menu}>

          <div className={`${props.activeMenu === '/home' ? s.navbar__menu__item_active : s.navbar__menu__item}`} onClick={() => navigate('/home')}>Dashboard</div>
          <div className={`${props.activeMenu === '/products' ? s.navbar__menu__item_active : s.navbar__menu__item}`} onClick={() => navigate('/products')}>Products</div>
          <div className={`${props.activeMenu === '/channels' ? s.navbar__menu__item_active : s.navbar__menu__item}`} onClick={() => navigate('/channels')}>Channels</div>
          <div className={`${props.activeMenu === '/sales' ? s.navbar__menu__item_active : s.navbar__menu__item}`}>Analytics</div>
          {
            props.activeMenu === '/products' && <>
              <div style={{ cursor: 'pointer', color: 'white' }}>
                <PageviewIcon onClick={() => {
                  setIsClicked(true)
                  setEnableSearch(!enableSearch)
                }}
                  className={`${s.pageviewIcon} ${isClicked ? s.clicked : ''}`}
                  style={{ fontSize: 'xx-large' }} />
              </div>
            </>
          }
        </div>
        <div className={s.navbar__user}>{user?.Profile?.name}</div>
      </div>
      {
        props.activeMenu === '/products' && enableSearch && <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <TypeAheadFilter products={props.products} gridApi={props.gridApi} onSearchClick={isClicked}>
          </TypeAheadFilter>
        </div>

      }

    </div>
  )
}

export default Navbar
