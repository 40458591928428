import React, { useState, useEffect } from 'react';
import logo from "./assets/bloom-local.png";
import "./App.css";
import { login } from './services/user';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

const Login = () => {
  const [token, setToken] = useState(null);
  const [isAuthenticationFlow, setIsAuthenticationFlow] = useState(false)
  const [storeUrl, setStoreUrl] = useState('')
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    // Check if OAuth2 token exists in the URL after redirect
    const urlParams = new URLSearchParams(window.location.search);
    const oauthToken = urlParams.get('token');
    if (oauthToken) {
      setToken(oauthToken);
    }
    window.localStorage.removeItem("columnOrder");
  }, []);

  const initiateSystemLogin = async () => {
    try {
      if (email && password) {
        const response = await login(email, password)
        const token = response?.data?.token
        if (token) {
          localStorage.setItem('token', token)
          navigate('/home')
        }
      }
    } catch (ex) {
      message.error('User not found')
      console.error(ex)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!token) {
        const apiKey = '31eb161ec12f0edccf03ef2f4da768d0';
        const scope = 'read_products';
        const redirectUri = 'https://egrader.bloomlocal.ai/api/auth/callback'; // Make sure this matches your actual callback URL

        // Construct the Shopify authentication URL
        const shopifyAuthURL = `https://${storeUrl}/admin/oauth/authorize?client_id=${apiKey}&scope=${scope}&redirect_uri=${redirectUri}`;
        window.location.href = shopifyAuthURL;
        return;
      }
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  return (
    isAuthenticationFlow ? (
      <div className="page">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div >
        <div className="auth__wrapper_store_login">
          <div className="auth__header">
            <div className="auth__header--title">Welcome back, Log in</div>
            <div className="auth__header--subtitle">
              Enter store url (without http/https)
            </div>
          </div>
          <div className="auth__form">
            <div className="input-grp relative w-full">
              <label>Shopify Store URL</label>
              <input
                className='input-padding'
                value={storeUrl}
                onChange={(e) => setStoreUrl(e.target.value)}
                placeholder='Example -  dummy-store.shopify.com' />
            </div>
          </div>
          <div className="auth__action--primary">
            <button className="primaryBtn" disabled={!storeUrl} onClick={handleSubmit}>Initiate Authenticate</button>
          </div>
        </div>
      </div >
    ) : (
      <div className="page">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div >
        <div className="auth__wrapper">
          <div className="auth__header">
            <div className="auth__header--title">Welcome back, Log in</div>
            <div className="auth__header--subtitle">
              Log back in to continue where you left off
            </div>
          </div>
          <div className="auth__form">
            <div className="input-grp relative w-full">
              <label>Email</label>
              <input className='input-padding' value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="input-grp relative w-full">
              <label>Password</label>
              <input className='input-padding' type='password' value={password} onChange={e => setPassword(e.target.value)} />
            </div>
          </div>
          <div className="auth__action--primary">
            <button className="primaryBtn" onClick={initiateSystemLogin}>Login</button>
          </div>
          <div className="auth__action--secondary-grp">
            <span>Authorize with</span>
            <button className="iconBtn" onClick={() => setIsAuthenticationFlow(true)}>Shopify</button>
            <button className="iconBtn">WooCommerce</button>
            <button className="iconBtn">Magento</button>
          </div>
          <div className="auth__action--tertiary">
            Don’t have an account? <span>Register</span>
          </div>
        </div>
      </div >

    )
  );
}

export default Login;
