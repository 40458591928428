import { useEffect, useState } from "react"
import { Api } from "../services/api"
import { duplicateProductsCountModel, reddinessScoreModel, seoGradeModel, validationIssuesDataModel } from "@/models/product"

const METRICS_NAMES = {
  VALIDATION_ISSUES: 'VALIDATION_ISSUES',
  REDDINESS_SCORE: 'REDDINESS_SCORE',
  DUPLICATE_PRODUCTS: 'DUPLICATE_PRODUCTS',
  TOTAL_PRODUCTS: 'TOTAL_PRODUCTS',
  SEO_GRADE: 'SEO_GRADE'
}

export const useMetricsSummary = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [validationIssuesData, setValidationIssuesData] = useState<validationIssuesDataModel>({})
  const [reddinessScore, setReddinessScore] = useState<reddinessScoreModel>({})
  const [duplicateProductsCount, setDuplicateProductsCount] = useState<duplicateProductsCountModel>({})
  const [totalProductsCount, setTotalProductsCount] = useState(0)
  const [seoGrade, setSeoGrade] = useState<seoGradeModel>({})

  useEffect(() => {
    const getMetricsData = async () => {
      try {
        const response = await Api.get('/metrics/summary')
        const validationMetic = response?.data?.find(metric => metric.name === METRICS_NAMES.VALIDATION_ISSUES)
        const redinnessMetric = response.data?.find(metric => metric.name === METRICS_NAMES.REDDINESS_SCORE)
        const duplicateProducts = response.data?.find(metric => metric.name === METRICS_NAMES.DUPLICATE_PRODUCTS)
        const totalProductsValue = response.data?.find(metric => metric.name === METRICS_NAMES.TOTAL_PRODUCTS)
        const seoGrades = response.data?.find(metric => metric.name === METRICS_NAMES.SEO_GRADE)
        setValidationIssuesData(validationMetic || {})
        setReddinessScore(redinnessMetric)
        setDuplicateProductsCount(duplicateProducts)
        setTotalProductsCount(totalProductsValue?.value || 0)
        setSeoGrade(seoGrades)
        setIsLoading(false)
      } catch (ex) {
        console.error(ex)
      }
    }
    getMetricsData()
  }, [])
  return { validationIssuesData, reddinessScore, duplicateProductsCount, isLoading, totalProductsCount, seoGrade }
}