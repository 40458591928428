// src/index.js (or your entry file)
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { UserProvider } from "./context/user";

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}`, // The GraphQL server endpoint
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <UserProvider>
      <App />
    </UserProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
