import React from 'react'
import { Card, Progress, Switch, Button, Divider } from 'antd';
import Navbar from '../components/Navbar/Navbar'
import { useUser } from '../context/user';
import s from './home.module.css';
import GMC_Icon from './../assets/GMC_Icon.png';
import whatsAppIcon from '../assets/whatsapp-business.svg';
import shopifyIcon from './../assets/logos_shopify.svg';

const Channels = () => {
  const { user } = useUser();

  return (
    <>
      <div className={s.page}>
        <Navbar
          username={user ? user?.Profile?.name : ''}
          activeMenu='/channels' />

        <div className={s.channels__wrapper}>
          <div className={s.channels__header}>
            <div className={s.channels__header__title}>
              My Channels
            </div>
          </div>

          <div className={s.channel__wrapper}>
            <Card
              classNames={{ body: s.channel__card }}
              hoverable
              style={{
                width: 254,
                background: '#00875a1a',
              }}>
              <div
                className={s.channel__brand}
                style={{ fontSize: 16 }}>
                <div className={s.channel__brand_icon}>
                  <img
                    src={shopifyIcon}
                    alt='Channel Icon'></img>
                </div>
                Shopify
              </div>
              <div className='my-auto'>
                <Progress
                  type='circle'
                  percent={26}
                  strokeColor='#FF991F99'
                  size={150}
                />
              </div>
              {/* <div style={{ margin: 10, fontSize: 12 }}>
                Readiness to Publish
              </div> */}
              <div className={s.channel__footer}>
                Active
                <Switch defaultChecked />
              </div>
            </Card>
            <Card
              classNames={{ body: s.channel__card }}
              hoverable
              style={{
                width: 254,
                background: '#00875a1a',
              }}>
              <div
                className={s.channel__brand}
                style={{ fontSize: 16 }}>
                <div className={s.channel__brand_icon}>
                  <img
                    src={whatsAppIcon}
                    alt='Channel Icon'></img>
                </div>
                WhatsApp Business
              </div>
              <div className='my-auto'>
                <Progress
                  type='circle'
                  percent={60}
                  size={150}
                />
              </div>
              {/* <div style={{ margin: 10, fontSize: 12 }}>
                Readiness to Publish
              </div> */}
              <div className={s.channel__footer}>
                Active
                <Switch defaultChecked />
              </div>
            </Card>
            <Card
              hoverable
              classNames={{ body: s.channel__card }}
              style={{
                width: 254,
                background: '#00875a1a',
              }}>
              <div
                className={s.channel__brand}
                style={{ fontSize: 16 }}>
                <div className={s.channel__brand_icon}>
                  <img
                    src={GMC_Icon}
                    alt='Channel Icon'></img>
                </div>
                GMC
              </div>
              <div className='my-auto'>
                <Progress
                  type='circle'
                  percent={85}
                  strokeColor='#00875ACC'
                  size={150}
                />
              </div>
              {/* <div style={{ margin: 10, fontSize: 12 }}>
                Readiness to Publish
              </div> */}
              <div className={s.channel__footer}>
                Active
                <Switch defaultChecked />
              </div>
            </Card>
            <Card
              hoverable
              bodyStyle={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'column',
                height: '100%',
                padding: 16,
              }}
              style={{
                width: 254,
                height: 316,
                background: '#fff',
              }}>
              {/* <div className={s.add_channel}> */}
              <div className={s.add_channel__brand}>
                <div className={s.add_channel__brand_icon}>
                  <img
                    src={shopifyIcon}
                    alt='Channel Icon'></img>
                </div>
                <div className={s.add_channel__brand_icon}>
                  <img
                    src={whatsAppIcon}
                    alt='Channel Icon'></img>
                </div>
                <div className={s.add_channel__brand_icon}>
                  <img
                    src={GMC_Icon}
                    alt='Channel Icon'></img>
                </div>
              </div>

              <div>
                <div className={s.add_channel__title}>
                  Connect a new sales channel
                </div>
                <div className={s.add_channel__subtitle}>
                  Shopify, AliExpress, Amazon, etc
                </div>
              </div>
              <div className={s.add_channel__footer}>
                <Button style={{ backgroundColor: 'white', color: '#0052cc' }}>
                  Add Sales Channel
                </Button>
              </div>
              {/* </div> */}
            </Card>
          </div>

          <Divider />
          
          <div className={s.channels__header}>
            <div className={s.channels__header__title}>
              Manage your Store
            </div>
          </div>

          <div className={s.channel__wrapper}>
            <Card
              classNames={{ body: s.channel__card }}
              hoverable
              style={{
                width: 254,
                height: 316,
                background: '#8F92A10D',
              }}>
              <div
                className={s.channel__brand}
                style={{ fontSize: 16 }}>
                <div className={s.channel__brand_icon}>
                  <img
                    src={shopifyIcon}
                    alt='Channel Icon'></img>
                </div>
                Shopify
              </div>
              <Progress
                type='circle'
                percent={26}
                strokeColor='#8F92A1CC'
                size={100}
              />
              <div style={{ fontSize: 12 }}>
                Readiness to Publish
              </div>
              <Button block style={{ color: '#0052cc' }}>
                  Start Training
                </Button>
              <div className={s.channel__footer}>
                Inactive
                <Switch defaultChecked={false} />
              </div>
            </Card>
          </div>    
        </div>
      </div>
    </>
  )
}

export default Channels