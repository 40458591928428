
import { Api, ApiWithoutToken } from "./api"

// const API_URL = process.env.API_URL || 'https://egrader.bloomlocal.ai/api'
// // const API_URL = 'http://localhost:4000/api'

export const login = async (email, password) => {
  const END_POINT = `/user/authenticate`
  try {
    const response = await ApiWithoutToken(END_POINT, {
      method: 'POST',
      data: {
        email,
        password
      }
    })
    return response
  } catch (ex) {
    console.error(ex)
    return ex
  }
}

export const fetchUser = async () => {
  try {
    const response = await Api.get('/user')
    return response
  } catch (ex) {
    console.error(ex)
    return ex
  }
}

export const updateUserPassword = async (email, password) => {
  const END_POINT = `/user`
  try {
    const response = await ApiWithoutToken(END_POINT, {
      method: 'PUT',
      data: {
        where: { Email: email },
        data: { Password: password }
      }
    })
    return response
  } catch (ex) {
    console.error(ex)
    return ex
  }
}

export const checkTokenAuthentication = async () => {
  const END_POINT = `/user/valid/token`
  try {
    const response = await Api(END_POINT)
    return response
  } catch (ex) {
    console.error(ex)
    // throw new Error(ex)
  }
}