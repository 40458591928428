import { Api } from './api.js';

type FetchProductInput = {
  id?: string;
};

export const fetchProducts = async () => {
  try {
    const response = await Api.get('/products');
    return response;
  } catch (ex) {
    console.error(ex);
    return ex;
  }
};

export const fetchProduct = async ({ id }) => {
  try {
    return await Api.get(`/products/${id}`);
  } catch (ex) {
    console.error(ex);
  }
};

export const fetchEnrichedProduct = async ({ id }) => {
  try {
    return await Api.get(`/products/${id}/enrich`);
  } catch (ex) {
    console.error(ex);
    return Promise.reject(ex)
  }
}

export const fetchEnrichedProductImages = async ({ id }) => {
  try {
    return await Api.get(`/products/${id}/enrich/images`)
  } catch (ex) {
    console.error(ex)
    return Promise.reject(ex)
  }
}

export const updateProductWithEnrichment = async ({ id, data }) => {
  try {
    return await Api.post(`/products/${id}/enrich`, data)
  } catch (ex) {
    console.error(ex)
    return Promise.reject(ex)
  }
}

export const getProductSeoGrading = async (productTitle, description) => {
  try {
    const apiUrl = `${process.env.REACT_APP_SEO_URL}/seo-grading`;
    const requestBody = {
      model: 'mistral',
      title: productTitle,
      description: description,
      stream: false
      // Include other SEO-related information in the prompt as needed
    };

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const result = await response.json();
      return result
    } else {
      console.error('Failed to grade SEO. HTTP Status:', response.status);
      throw new Error()
    }
  } catch (error) {
    console.error('Error grading SEO:', error);
    throw new Error()
  }
}
export const updateProduct = async ({ id, data }) => {
  try {
    return await Api.post(`/products/${id}/update`, data)
  } catch (ex) {
    console.error(ex)
    return Promise.reject(ex)
  }
}

export const uploadImage = async (productId, file) => {
  try {
    const endpoint = `/products/${productId}/update-image`;
    const formData = new FormData();
    formData.append('imageFile', file);
    console.log('formData', formData);
    const response = await Api.post(
      endpoint,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      }
    );
    // console.log('Image upload response:', response.data);
    return (response.data);

  } catch (error) {
    return (error);
  }
};

