import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import s from './home.module.css';
import shopifyIcon from './../assets/logos_shopify.svg';
import productIcon from './../assets/dummy_product.png';
import storeIcon from './../assets/checker-logo.png';
import GMC_Icon from './../assets/GMC_Icon.png';
import whatsAppIcon from '../assets/whatsapp-business.svg';
import {
  PlusOutlined,
  ClockCircleOutlined,
  ArrowUpOutlined,
} from '@ant-design/icons';
import { gray } from '@ant-design/colors';
import { Button, Typography, Card, Progress, Switch, Collapse } from 'antd';
import { Column, Gauge } from '@ant-design/plots';
import { useUser } from '../context/user';
import { useMetricsSummary } from '../hooks/useMetricsSummary';
import { fetchUser } from '../services/user';
import DemoBarChart from './ReddinessChart';

const { Text } = Typography;

const ColumnChart: React.FC<any> = ({ data }) => {
  const props = {
    data,
    xField: 'platform',
    yField: 'value',
    maxBarWidth: 20,
    style: {
      fill: '#F3F6F8',
      cursor: 'pointer',
    },
  };

  return <Column {...props} />;
};

const ReadinessGauge = ({ score, isLoading }) => {
  const config = {
    width: 420,
    height: 360,
    autoFit: true,
    data: {
      target: `${score}`,
      total: 100,
      name: 'score',
    },
    legend: false,
  };
  return (
    <Card
      style={{ height: 320 }}
      bordered
      hoverable
      loading={isLoading}>
      <Gauge {...config} />
    </Card>
  );
};

const ChannelGauge = ({ value }) => {
  const config = {
    width: 240,
    height: 220,
    autoFit: true,
    data: {
      target: value,
      total: 100,
      name: 'score',
    },
    legend: false,
  };
  return <Gauge {...config} />;
};

const StatsComponent: React.FC<{
  title: string;
  subtitle: string;
  stat: number;
  color: string;
  isLoading: boolean;
}> = ({ title, subtitle, stat, color, isLoading = true }) => {
  return (
    <Card
      hoverable
      bodyStyle={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      style={{
        width: 380,
        height: 246,
        background: '#F3F6F8',
        borderRadius: 20,
      }}
      loading={isLoading}>
      <div style={{ margin: 10 }}>
        {/* <img src={statsIcon} alt="Stats"></img> */}
      </div>
      <div style={{ fontSize: 16 }}>{title}</div>
      <div style={{ fontSize: 60 }}>{stat || 0}</div>
      <div
        style={{ margin: 0, fontSize: 14, color: 'rgba(143, 146, 161, 0.80)' }}>
        {subtitle || 0}
      </div>
      <div
        style={{
          marginTop: 10,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <div style={{ color: '#4B9F47' }}>
          <ArrowUpOutlined />
          105.23 %
        </div>
        <Button style={{ backgroundColor: 'white', color: '#0052cc' }}>
          See Issues
        </Button>
      </div>
    </Card>
  );
};

export const Home = () => {
  // const { user } = useUser();
  const { user, updateUser } = useUser();
  const {
    validationIssuesData,
    reddinessScore,
    duplicateProductsCount,
    isLoading,
    totalProductsCount,
    seoGrade,
  } = useMetricsSummary();

  const columnChartReadiness = [
    { platform: 'GMC', value: reddinessScore?.value || 0 },
    // { platform: 'Amazon Saudi', value: 0 },
    // { platform: 'WooCommerce', value: 0 },
    // { platform: 'Etsy', value: 0 },
    // { platform: 'Bing Shopping', value: 0 },
    // { platform: 'Aliexpress', value: 0 },
    { platform: 'WhatsApp', value: 40 },
    { platform: 'Shopify', value: 50 },
  ];

  useEffect(() => {
    if (!user) {
      fetchUser().then((res) => {
        updateUser(res.data?.data);
      });
    }
  }, []);

  const getColorBasedOnGrade = (percentage: any) => {
    if (percentage <= 100 && percentage > 80) {
      return 'success';
    } else if (percentage <= 80 && percentage > 50) {
      return 'normal';
    } else {
      return 'exception';
    }
  };

  return (
    <>
      <div className={s.page}>
        <Navbar
          username={user ? user?.Profile?.name : ''}
          activeMenu='/home'
        />

        <div className={s.welcome__header__wrapper}>
          <div className={s.welcome__header}>
            <div className={s.welcome__header__info}>
              <div className={s.welcome__header__info_title}>
                Hello, {user?.Profile?.name} 👋
              </div>
              <div className={s.welcome__header__info_subtitle}>
                Sales Assistant just got you 24 sales today!
              </div>
            </div>
            <div className={s.welcome__header__action}>
              <Button
                icon={<PlusOutlined />}
                ghost>
                Take Key Action
              </Button>
            </div>
          </div>
        </div>

        <div className={s.readiness__wrapper}>
          <div className={s.readiness__left}>
            <div className={s.readiness__left__summary}>
              <div className={s.readiness__left__store}>
                <div className={s.readiness__left__store_logo}>
                  <img
                    src={storeIcon}
                    alt='Icon'></img>
                </div>
                <div className={s.readiness__left__store_info}>
                  <div className={s.readiness__left__store_name}>
                    {user?.Profile?.name} Store
                  </div>
                  <div>
                    <ClockCircleOutlined style={{ color: gray[2] }} /> Last sync{' '}
                    <span>1 day ago</span>
                  </div>
                </div>
              </div>
              <div className={s.readiness__left__store_total}>
                <div>All Products</div>
                <div>{totalProductsCount}</div>
              </div>
            </div>
            <div className={s.readiness__left__graph_title}>Your Readiness</div>
            {reddinessScore?.value ? (
              <div className={s.readiness__left__graph_container}>
                <Progress
                  type='circle'
                  percent={reddinessScore?.value}
                  size={250}
                />
              </div>
            ) : // <ReadinessGauge
            //   isLoading={isLoading}
            //   score={reddinessScore?.value || 0}></ReadinessGauge>
            undefined}
            <div className={s.readiness__left__graph_caption}>
              {/* 980 products ready to publish */}
            </div>
          </div>
          <div className={s.readiness__right}>
            <div className={s.readiness__right__header}>
              <div className={s.readiness__right__header_title}>
                Readiness to Publish
              </div>
              <div className={s.readiness__right__header_subtitle}>
                <ClockCircleOutlined style={{ color: gray[2] }} /> Last updated:
                1 day ago
              </div>
            </div>
            <div>
              {/* <ColumnChart data={columnChartReadiness} /> */}
              <DemoBarChart />
            </div>
          </div>
        </div>
        <div className={s.overview__wrapper}>
          <div className={s.overview__header}>
            <div className={s.overview__header__title}>Overview</div>
          </div>
          <div className={s.stats__wrapper}>
            <StatsComponent
              title='Total Duplication'
              subtitle='products affected'
              stat={124}
              color='#de350b0d'
              isLoading={false}
            />
            <StatsComponent
              title='Total Accuracy'
              subtitle='products affected'
              stat={88.8}
              color='#de350b0d'
              isLoading={false}
            />
            <StatsComponent
              title='Validation Issues'
              subtitle='issue'
              stat={validationIssuesData?.value || 0}
              color='#ff991f0d'
              isLoading={isLoading}
            />
          </div>
          <div className={s.total__wrapper}>
            <div className={s.total__card}>
              <div className={s.total__card__title}>Total Completeness</div>
              <div className={s.total__card__subtitle}>
                Products with complete information
              </div>
              <Progress
                percent={30}
                strokeColor='#FF991F99'
                size={'small'}
              />
              <div className={s.total__card__footer}>
                <div className={s.total__card__footer_media}>
                  <img
                    src={productIcon}
                    alt='Icon'></img>
                  <img
                    src={productIcon}
                    alt='Icon'></img>
                  <img
                    src={productIcon}
                    alt='Icon'></img>
                </div>
                <div className={s.total__card__footer_count}>
                  48 products affected
                </div>
              </div>
            </div>
            <div className={s.total__card}>
              <div className={s.total__card__title}>Total Consistency</div>
              <div className={s.total__card__subtitle}>
                Consistent data formats and attributes
              </div>
              <Progress
                percent={40}
                strokeColor='#00875ACC'
                size={'small'}
              />
              <div className={s.total__card__footer}>
                <div className={s.total__card__footer_media}>
                  <img
                    src={productIcon}
                    alt='Icon'></img>
                  <img
                    src={productIcon}
                    alt='Icon'></img>
                  <img
                    src={productIcon}
                    alt='Icon'></img>
                </div>
                <div className={s.total__card__footer_count}>
                  30 products affected
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <Card
            loading={isLoading}
            title='Catalog SEO Grade'
            bordered
            style={{
              display: 'flex',
              padding: '24 0',
              flexDirection: 'column',
              maxWidth: 1220,
              margin: 'auto',
            }}>
            <div style={{ width: '100%', padding: 30 }}>
              <Collapse
                items={[
                  {
                    key: '1',
                    label: (
                      <Text>
                        Overall SEO Grade -
                        <Text
                          strong
                          style={{ fontSize: 20 }}>
                          {`${seoGrade?.value || 0} %`}
                        </Text>
                      </Text>
                    ),
                    children: (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          justifyContent: 'space-evenly',
                        }}>
                        {Object.keys(seoGrade?.specs || {}).map((key) => {
                          return (
                            <Card
                              key={key}
                              style={{ marginBottom: '10px', width: 350 }}>
                              <Text>{`${key}: ${seoGrade?.specs[key]} %`}</Text>
                              <Progress
                                percent={seoGrade?.specs[key]}
                                status={getColorBasedOnGrade(
                                  seoGrade?.specs[key]
                                )}
                                style={{ height: '20px' }}
                              />
                            </Card>
                          );
                        })}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </Card>
        </div>
        <div className={s.products__wrapper}>
          <div className={s.products__header}>
            <div className={s.products__header__title}>My Channels</div>
            <div className={s.products__header__filters}></div>
          </div>
          <div className={s.channel__wrapper}>
            <Card
              classNames={{ body: s.channel__card }}
              hoverable
              style={{
                width: 254,
                background: '#00875a1a',
              }}>
              <div
                className={s.channel__brand}
                style={{ fontSize: 16 }}>
                <div className={s.channel__brand_icon}>
                  <img
                    src={shopifyIcon}
                    alt='Channel Icon'></img>
                </div>
                Shopify
              </div>
              <div className='my-auto'>
                <Progress
                  type='circle'
                  percent={26}
                  strokeColor='#FF991F99'
                  size={150}
                />
              </div>
              {/* <div style={{ margin: 10, fontSize: 12 }}>
                Readiness to Publish
              </div> */}
              <div className={s.channel__footer}>
                Active
                <Switch defaultChecked />
              </div>
            </Card>
            <Card
              classNames={{ body: s.channel__card }}
              hoverable
              style={{
                width: 254,
                background: '#00875a1a',
              }}>
              <div
                className={s.channel__brand}
                style={{ fontSize: 16 }}>
                <div className={s.channel__brand_icon}>
                  <img
                    src={whatsAppIcon}
                    alt='Channel Icon'></img>
                </div>
                WhatsApp Business
              </div>
              <div className='my-auto'>
                <Progress
                  type='circle'
                  percent={60}
                  size={150}
                />
              </div>
              {/* <div style={{ margin: 10, fontSize: 12 }}>
                Readiness to Publish
              </div> */}
              <div className={s.channel__footer}>
                Active
                <Switch defaultChecked />
              </div>
            </Card>
            <Card
              hoverable
              classNames={{ body: s.channel__card }}
              style={{
                width: 254,
                background: '#00875a1a',
              }}>
              <div
                className={s.channel__brand}
                style={{ fontSize: 16 }}>
                <div className={s.channel__brand_icon}>
                  <img
                    src={GMC_Icon}
                    alt='Channel Icon'></img>
                </div>
                GMC
              </div>
              <div className='my-auto'>
                <Progress
                  type='circle'
                  percent={85}
                  strokeColor='#00875ACC'
                  size={150}
                />
              </div>
              {/* <div style={{ margin: 10, fontSize: 12 }}>
                Readiness to Publish
              </div> */}
              <div className={s.channel__footer}>
                Active
                <Switch defaultChecked />
              </div>
            </Card>
            <Card
              hoverable
              bodyStyle={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'column',
                height: '100%',
                padding: 16,
              }}
              style={{
                width: 254,
                height: 316,
                background: '#fff',
              }}>
              {/* <div className={s.add_channel}> */}
              <div className={s.add_channel__brand}>
                <div className={s.add_channel__brand_icon}>
                  <img
                    src={shopifyIcon}
                    alt='Channel Icon'></img>
                </div>
                <div className={s.add_channel__brand_icon}>
                  <img
                    src={whatsAppIcon}
                    alt='Channel Icon'></img>
                </div>
                <div className={s.add_channel__brand_icon}>
                  <img
                    src={GMC_Icon}
                    alt='Channel Icon'></img>
                </div>
              </div>

              <div>
                <div className={s.add_channel__title}>
                  Connect a new sales channel
                </div>
                <div className={s.add_channel__subtitle}>
                  Shopify, AliExpress, Amazon, etc
                </div>
              </div>
              <div className={s.add_channel__footer}>
                <Button style={{ backgroundColor: 'white', color: '#0052cc' }}>
                  Add Sales Channel
                </Button>
              </div>
              {/* </div> */}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
