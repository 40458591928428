/* eslint-disable @typescript-eslint/no-unused-vars */
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

export const AgGridCommonTable = (props) => {

    const [columnDefs, setColumnDefs] = useState(props.columnDefs);
    const apiRef = useRef(null);
    const gridApiRef = useRef(null);


    useEffect(() => {
        const storedColumnOrder = localStorage.getItem(props.columnReorederLocal);
        if (storedColumnOrder) {
            const columnState = JSON.parse(storedColumnOrder);
            const columnDefsMap = {};
            props.columnDefs.forEach(col => {
                const key = col.colId || col.field;
                columnDefsMap[key] = col;
            });
            const updatedColumnDefs = columnState.map(colState => {
                const key = colState.colId;
                const originalColumn = columnDefsMap[key];
                if (originalColumn) {
                    return { ...originalColumn };
                }
                return null;
            }).filter(col => col !== null);

            setColumnDefs(updatedColumnDefs);
        }
    }, []);


    const handleColumnMoved = (event) => {
        const newColumnState = event.columnApi.getColumnState();
        localStorage.setItem(props.columnReorederLocal, JSON.stringify(newColumnState));
    };

    const getRowHeight = useCallback((params) => {
        return 60;
    }, []);

    const handleContextMenu = (params) => {
        params.event.preventDefault();
        const selectedRows = apiRef.current.getSelectedRows();
        props.contextMenu(params.event, selectedRows, params);
    };

    const onGridReady = (params) => {
        apiRef.current = params.api;
        gridApiRef.current = params.api;
        props.onGridReady(params);
    };

    const onCellValueChanged = (event) => {

        if (event.node.selected && apiRef.current.getSelectedNodes().length > 1) {
            const editedColumn = event.colDef.field;
            const selectedRows = apiRef.current.getSelectedRows();
            if (event.oldValue !== event.newValue) {
                selectedRows.forEach(selectedRow => {
                    selectedRow[editedColumn] = event.data[editedColumn];
                });
                apiRef.current.refreshCells({ force: true });
                props.inlineSaveMulti(event, selectedRows);
            }
        } else {
            props.inlineSave(event);
        }
    }

    return (
        <>
            <AgGridReact
                rowData={props.rowData}
                columnDefs={columnDefs}
                pagination={props.pagination}
                rowSelection={props.rowSelection}
                rowDragManaged={props.rowDragManaged}
                rowDragEntireRow={props.rowDragEntireRow}
                onColumnMoved={handleColumnMoved}
                getRowHeight={getRowHeight}
                onCellContextMenu={handleContextMenu}
                preventDefaultOnContextMenu={props.preventDefaultOnContextMenu}
                onGridReady={onGridReady}
                onCellValueChanged={onCellValueChanged}
                suppressRowClickSelection={props.suppressRowClickSelection}
                gridOptions={props.gridOptions}
            />
        </>
    );
}

export default AgGridCommonTable;