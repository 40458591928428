import { Button, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Card, Progress, Typography } from 'antd';
import { getProductSeoGrading } from '../../services/product';

const { Title, Text } = Typography;

export const SeoGrading = ({ productDetail, isOpen, setIsOpen }) => {
  const [seoReport, setSeoReport] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (productDetail && productDetail?.title) {
      setIsLoading(true);
      getProductSeoGrading(productDetail?.title, productDetail?.description)
        .then((response) => {
          setSeoReport(response);
          setIsLoading(false);
        })
        .catch((ex) => {
          console.log(ex);
          setIsLoading(false);
        });
    }
  }, [productDetail]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const renderBarGraph = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    const overallSEOGrade =
      seoReport?.overallSEOGrade ||
      (seoReport?.seoGrade && seoReport.seoGrade.overallSEOGrade);

    if (overallSEOGrade === undefined) {
      return <div>No Data Available</div>;
    }

    const criteriaArray: any = Object.entries(seoReport.seoGrade || {});

    return (
      <div>
        <div>
          <Title level={4}>
            Overall SEO Grade: {overallSEOGrade.grade} (
            {overallSEOGrade.percentage}%)
          </Title>
          <Progress
            percent={overallSEOGrade.percentage}
            status={getColorBasedOnGrade(overallSEOGrade.grade)}
            style={{ height: '20px' }}
          />
          <div>
            <Text>Explanation: {overallSEOGrade.explanation}</Text>
          </div>
          <div>
            <Text>Recommendation: {overallSEOGrade.recommendation}</Text>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            marginTop: 20,
          }}>
          {criteriaArray.map(
            ([
              criteria,
              { grade, percentage, explanation, recommendation },
            ]) => (
              <Card
                key={criteria}
                style={{ marginBottom: '10px', width: 400 }}>
                <Text>
                  {criteria}: {grade} ({percentage}%)
                </Text>
                <Progress
                  percent={percentage}
                  status={getColorBasedOnGrade(grade)}
                  style={{ height: '20px' }}
                />
                <div>
                  <Text>Explanation: {explanation}</Text>
                </div>
                <div>
                  <Text>Recommendation: {recommendation}</Text>
                </div>
              </Card>
            )
          )}
        </div>
      </div>
    );
  };

  // Function to determine color based on grade
  const getColorBasedOnGrade = (grade): any => {
    if (grade === 'A') {
      return 'success';
    } else if (grade === 'B') {
      return 'info';
    } else {
      return 'error';
    }
  };

  return (
    <>
      <Modal
        width={1000}
        title='SEO Criteria Grades'
        open={isOpen}
        footer={null}
        onCancel={handleCancel}>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Spin />
          </div>
        ) : (
          renderBarGraph()
        )}
      </Modal>
    </>
  );
};
