import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import { syncData } from '../services/platform';
import { Spinner } from '../components/Spinner';
import s from "./home.module.css";
import "../App.css";

const PlatformSync = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true)
  const [userEmail, setUserEmail] = useState()
  const navigate = useNavigate();

  useEffect(() => {
    const storeUrl = 'quickstart-e2444389.myshopify.com'
    const apiVersion = '2023-10'
    const accessToken = searchParams.get('accessToken') || ''

    syncData({ storeUrl, apiVersion, accessToken }).then(res => {
      const userEmail = res?.data?.data?.userEmail || ''
      const userId = res?.data?.data?.userId || ''
      setUserEmail(userEmail)
      localStorage.setItem('userId', userId)
      setIsLoading(false)
    }).catch(ex => {
      console.error(ex)
    })
  }, [searchParams])

  const proceedToHome = () => {
    navigate(`/set-password?userEmail=${userEmail}`)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className={s.stats__card} style={{ width: 500, height: 400 }}>
          {isLoading ?
            <>
              <p>Please wait, we are syncing your data</p>
              <div style={{ margin: 20 }}><Spinner /></div>
            </> : <>
              <p>Thanks for your patience, Data sync is completed</p>
              <button
                onClick={proceedToHome}
                style={{ marginTop: 20 }}
                className='primaryBtn'>
                Proceed to Home
              </button>
            </>}
        </div>
      </div>
    </div>
  )
}

export default PlatformSync