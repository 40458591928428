/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import statsIcon from '../../assets/stats_icon.svg';
import issuesIcon from '../../assets/issues_icon.svg';
import readyIcon from '../../assets/ready_icon.svg';
import readinessIcon from '../../assets/readiness_icon.svg';
import productTemplate from '../../assets/templates/ProductCreateTemplate.csv';
import s from './../home.module.css';
import NoImage from '../../assets/NoImage.jpeg';
import {
  fetchProducts,
  updateProduct,
  uploadImage,
} from '../../services/product';
import { fetchUser } from '../../services/user';
import Navbar from '../../components/Navbar/Navbar';
import { useUser } from '../../context/user';
import {
  Tag,
  Button,
  Table,
  Tooltip,
  Typography,
  Card,
  Image,
  Dropdown,
  Space,
  Input,
  Upload,
  message,
  MenuProps,
  UploadProps,
  Spin,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ProductModel } from '../../models/product';
import {
  BorderBottomOutlined,
  DownOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { GalleryView } from './GallaryView';

import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { CustomCellRendererProps } from 'ag-grid-react';
import AgGridCommonTable from '../../components/ag-grid-table';
import { ChevronRight } from 'react-feather';
import SendToChannelPopup from '../../components/contextMenuOptionPopup';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import IconButton from '@mui/material/IconButton';

const contextMenuStyles: React.CSSProperties = {
  position: 'fixed',
  zIndex: 1000,
  backgroundColor: 'white',
  border: '1px solid #ccc',
  boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
  padding: '5px 0',
};

const contextMenuItemStyles: React.CSSProperties = {
  padding: '5px 20px',
  cursor: 'pointer',
};

const contextMenuItemHoverStyles: React.CSSProperties = {
  backgroundColor: '#f0f0f0',
};

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
  issues: [];
}

const FONT_SIZE = 15;

const { Text } = Typography;

const StatsComponent: React.FC<{
  title: string;
  stat: number;
  cardColor: string;
  titleColor: string;
  icon: string;
}> = ({ title, stat, cardColor, titleColor, icon }) => {
  return (
    <Card
      hoverable
      style={{
        width: 285,
        height: 160,
        background: cardColor,
      }}>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'flex-start',
            justifyContent: 'left',
          }}>
          <img
            src={icon}
            alt='Stats'></img>
        </div>
        <div style={{ color: titleColor, marginLeft: 10 }}>{title}</div>
      </div>
      <div className='text-4xl font-bold my-auto'>{stat || 0}</div>
    </Card>
  );
};

export const Products = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const { user, updateUser } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [toggleToGridView, setToggleToGridView] = useState(false);
  const containerStyle = useMemo(
    () => ({ width: '100%', height: '100%', padding: '15px' }),
    []
  );
  const gridStyle = useMemo(() => ({ height: '700px', width: '100%' }), []);
  const [sendToChannelVisible, setSendToChannelVisible] = useState(false);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [searchText, setSearchText] = useState('');

  const [commonDropDownList, setCommonList] = useState([]);
  const [titleNmae, setTitleName] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCellValue, setSelectedValue] = useState(null);
  const [selectedRowValue, setSelectedRowValue] = useState({});
  const [selectedField, setSelectedField] = useState(null);

  const channelList = [
    { key: 'gmsmeta', value: 'GMC Meta' },
    { key: 'chanel1', value: 'Channel 1' },
    { key: 'channel2', value: 'Channel 2' },
  ];

  const serviceList = [
    { key: 'english', value: 'English Genx' },
    { key: 'arabic', value: 'Arabic Genx' },
    { key: 'service1', value: 'Service 1' },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: 'IMAGE',
      dataIndex: 'image_url',
      key: 'image_url',
      render: (imageUrl) => (
        <Image
          width={55}
          height={55}
          src={imageUrl || NoImage}
        />
      ),
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      render: (name: string, record: ProductModel) => {
        return (
          <Text
            onClick={() => navigate(`/product/${record.id}`)}
            style={{ fontSize: FONT_SIZE, cursor: 'pointer' }}>
            {name}
          </Text>
        );
      },
    },
    {
      title: 'BRANDS',
      dataIndex: 'brands',
      key: 'brands',
      render: (text) => (
        <Text style={{ fontSize: FONT_SIZE, cursor: 'pointer' }}>
          {text ? text : '-'}
        </Text>
      ),
    },
    {
      title: 'VENDOR',
      dataIndex: 'vendor',
      key: 'vendor',
      render: (vendor) => (
        <Text style={{ fontSize: FONT_SIZE, cursor: 'pointer' }}>{vendor}</Text>
      ),
    },
    {
      title: 'PLATFORM',
      dataIndex: 'source_platform',
      filters: [
        {
          text: 'GMC',
          value: 'Google Merchant Center',
        },
        {
          text: 'Bloom',
          value: 'manual',
        },
      ],
      onFilter: (value: string, record: any) => {
        return record?.source_platform?.indexOf(value) === 0;
      },

      key: 'source_platform',
      render: (source_platform) => (
        <Text style={{ fontSize: FONT_SIZE, cursor: 'pointer' }}>
          {source_platform}
        </Text>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'external_id',
      key: 'external_id',
      render: (external_id) => (
        <Text
          copyable
          style={{ fontSize: FONT_SIZE }}>
          {external_id}
        </Text>
      ),
    },
    {
      title: 'ISSUES',
      key: 'issues',
      dataIndex: 'issues',
      render: (_, { issues }) => (
        <Tooltip
          placement='top'
          title={() => {
            return (
              <div style={{ background: 'white' }}>
                <ul>
                  {issues?.map((issue: any) => {
                    return (
                      <li>
                        <Text style={{ fontSize: FONT_SIZE }}>
                          {issue?.description}
                        </Text>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          }}>
          <Tag color='red'>
            <Text style={{ fontSize: FONT_SIZE }}>{issues?.length}</Text>
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Archived',
          value: 'archived',
        },
      ],
      onFilter: (value: string, record: any) => {
        return record?.status?.indexOf(value) === 0;
      },
      render: (status) => (
        <Tag color={status === 'active' ? 'green' : 'orange'}>
          <Text style={{ fontSize: FONT_SIZE }}>{status}</Text>
        </Tag>
      ),
    },
  ];
  const uploadProps: UploadProps = {
    name: 'csvFile',
    action: `${process.env.REACT_APP_API_URL}/products/bulk`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const createProductOptions: MenuProps['items'] = [
    {
      label: (
        <a
          href={productTemplate}
          target='_black'>
          <Button target='_blank'>Download template</Button>
        </a>
      ),
      key: '0',
    },
    {
      label: (
        <Upload {...uploadProps}>
          <Button>Upload product template with data</Button>
        </Upload>
      ),
      key: '1',
    },
  ];

  const imageRenderer = (params: CustomCellRendererProps) => (
    <img
      alt=''
      src={params.value || NoImage}
      style={{
        maxWidth: '58px',
        height: '58px',
      }}
    />
  );

  const ImageUploaderCellEditor = (params) => {
    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      handleImageChange(selectedFile, params);
    };

    return (
      <IconButton
        component='label'
        htmlFor='upload-file'
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <FileUploadOutlinedIcon
          style={{
            fontSize: 'larger',
          }}
        />
        <input
          type='file'
          id='upload-file'
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </IconButton>
    );
  };

  const handleImageChange = async (selectedFile, params) => {
    // setIsLoading(true);
    try {
      const productId = params.data.id;
      let responseData = await uploadImage(productId, selectedFile);
      // fetchProducts().then((res) => {
      //   setProducts(res.data);
      //   setIsLoading(false);
      // });
      console.log('responseData', responseData);
    } catch (error) {
      console.error('Error handling image change:', error);
    }
  };

  const IssuesTooltip = (params: any) => {
    const issues = params.value || [];
    return (
      <div style={{ background: 'white' }}>
        <ul>
          {issues.map((issue: any, index: number) => (
            <li key={index}>
              <span>{issue.description}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const gridOptions = {
    components: {
      issuesTooltip: IssuesTooltip,
    },
  };

  const issuesRenderer = (params: CustomCellRendererProps) => {
    const issues = params.value || [];
    return (
      <>
        <Tag color='red'>
          <span>{issues.length}</span>
        </Tag>
      </>
    );
  };

  const statusRender = (params) => {
    return (
      <Tag color={params?.value === 'active' ? 'green' : 'orange'}>
        <Text style={{ fontSize: FONT_SIZE }}>{params?.value || ''}</Text>
      </Tag>
    );
  };

  const [colDefs] = useState<ColDef[]>([
    {
      field: 'image_url',
      width: 130,
      cellRenderer: imageRenderer,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerName: 'IMAGE',
      editable: true,
      cellEditor: ImageUploaderCellEditor,
    },
    {
      field: 'title',
      width: 300,
      sortable: true,
      filter: true,
      headerName: 'TITLE',
      editable: true,
    },
    {
      field: 'brand',
      width: 130,
      sortable: true,
      filter: true,
      headerName: 'BRANDS',
      editable: true,
    },
    {
      field: 'vendor',
      width: 130,
      sortable: true,
      filter: true,
      headerName: 'VENDOR',
      editable: true,
    },
    {
      field: 'source_platform',
      sortable: true,
      filter: true,
      width: 130,
      headerName: 'PLATFORM',
      editable: true,
    },
    {
      field: 'external_id',
      width: 120,
      sortable: true,
      filter: true,
      headerName: 'ID',
      editable: true,
    },
    {
      field: 'issues',
      width: 100,
      tooltipField: 'issues',
      cellRenderer: issuesRenderer,
      sortable: true,
      filter: true,
      headerName: 'ISSUES',
      tooltipComponent: 'issuesTooltip',
    },
    {
      field: 'status',
      width: 100,
      cellRenderer: statusRender,
      sortable: true,
      filter: true,
      headerName: 'STATUS',
    },
    {
      colId: 'actionColumn',
      headerName: '',
      width: 50,
      sortable: false,
      filter: false,
      cellRenderer: (params) => (
        <ChevronRight
          onClick={() => navigate(`/product/${params.data.id}`)}
          style={{ cursor: 'pointer' }}
        />
      ),
    },
  ]);

  useEffect(() => {
    fetchProducts().then((res) => {
      if (res && res.data && res.data.length > 0) {
        setProducts(res.data);
      } else {
        setProducts([]);
      }
      setIsLoading(false);
    });
    if (!user) {
      fetchUser().then((res) => {
        updateUser(res.data?.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTotalIssues = () => {
    let total = 0;
    products?.forEach((product: ProductModel) => {
      total += product?.issues?.length || 0;
    });
    return total;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [contextMenuVisible]);

  const displayContextMenu = (event, selectedRows, params) => {
    const cellValue = params.value;
    const rowValue = params.data;
    const filed = params.colDef.field;

    setSelectedRows(selectedRows);
    if (selectedRows && selectedRows.length === 0) {
      setSelectedValue(cellValue);
      setSelectedField(filed);
      setSelectedRowValue(rowValue);
    }
    setContextMenuPosition({ x: event.clientX, y: event.clientY });
    setContextMenuVisible(true);
  };

  const closeContextMenu = () => {
    setContextMenuVisible(false);
  };

  const openSendToChannelPopup = () => {
    setCommonList(channelList);
    setTitleName('Send to Channel');
    setSendToChannelVisible(true);
    closeContextMenu();
  };

  const openSendToServicePopup = () => {
    setCommonList(serviceList);
    setTitleName('Send to Micro Service');
    setSendToChannelVisible(true);
    closeContextMenu();
  };

  const sendToImageOptimisation = () => {
    console.log(
      'selectedField',
      selectedField,
      'selectedRowValue',
      selectedRowValue,
      'selectedCellValue',
      selectedCellValue,
      'selected rows',
      selectedRows
    );
    closeContextMenu();
    // handle api call here
  };

  const contextMenuTemplate = [
    { label: 'Send to Channel', action: openSendToChannelPopup },
    { label: 'Send to Micro Service', action: openSendToServicePopup },
    { label: 'Send to Image Optimisation', action: sendToImageOptimisation },
  ];

  const handleDocumentClick = (event) => {
    const isClickInsideMenu = event.target.closest('.context-menu-item');
    if (contextMenuVisible && !isClickInsideMenu) {
      closeContextMenu();
    }
  };

  const closeSendToChannelPopup = () => {
    setSendToChannelVisible(false);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onSearchTextChange = (event) => {
    setSearchText(event.target.value);
    gridApi.setQuickFilter(event.target.value);
  };

  const inLineEditSaveSingle = async (event) => {
    try {
      const editedValue = event.newValue;
      const oldValue = event?.data || {};
      const fieldName = event?.colDef?.field;

      const payload = {
        id: oldValue.id,
        external_id:
          fieldName === 'external_id' && editedValue
            ? editedValue
            : oldValue.external_id,
        user_id: oldValue.user_id,
        title:
          fieldName === 'title' && editedValue ? editedValue : oldValue.title,
        description: oldValue.description,
        vendor:
          fieldName === 'vendor' && editedValue ? editedValue : oldValue.vendor,
        brand:
          fieldName === 'brand' && editedValue ? editedValue : oldValue.brand,
        category: oldValue.category,
        barcode: oldValue.barcode,
        product_type: oldValue.product_type,
        status: oldValue.status,
        image_url: oldValue.image_url,
        locale: oldValue.locale,
        source_platform:
          fieldName === 'source_platform' && editedValue
            ? editedValue
            : oldValue.source_platform,
        images_url: oldValue.images_url,
        additional_info: oldValue.additional_info,
      };
      console.log('payload', payload);
      await updateProduct({
        id: oldValue.id,
        data: payload,
      });
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const inLineEditSaveMulti = async (e, selectedRow) => {
    try {
      if (selectedRow && selectedRow.length > 0) {
        for (let i = 0; i < selectedRow.length; i++) {
          const payload = {
            id: selectedRow[i].id,
            external_id: selectedRow[i].external_id,
            user_id: selectedRow[i].user_id,
            title: selectedRow[i].title,
            description: selectedRow[i].description,
            vendor: selectedRow[i].vendor,
            brand: selectedRow[i].brand,
            category: selectedRow[i].category,
            barcode: selectedRow[i].barcode,
            product_type: selectedRow[i].product_type,
            status: selectedRow[i].status,
            image_url: selectedRow[i].image_url,
            locale: selectedRow[i].locale,
            source_platform: selectedRow[i].source_platform,
            images_url: selectedRow[i].images_url,
            additional_info: selectedRow[i].additional_info,
          };
          console.log('payload', payload);
          await updateProduct({
            id: selectedRow[i].id,
            data: payload,
          });
        }
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const handleChange = (event) => {
    // console.log('event', event)
    // get selected value here
  };

  const handleConfirm = () => {
    console.log(
      'selectedField',
      selectedField,
      'selectedRowValue',
      selectedRowValue,
      'selectedCellValue',
      selectedCellValue,
      'selected rows',
      selectedRows
    );
    // handle api call here
  };

  return (
    <div className={s.page}>
      <Navbar
        activeMenu='/products'
        username={user ? user?.Profile?.name : ''}
        products={products}
        gridApi={gridApi}
      />
      <div className={s.summary__wrapper}>
        <div className={s.stats__wrapper}>
          <StatsComponent
            title='Total Products'
            stat={products?.length || 0}
            cardColor='#0052CC0D'
            titleColor='#00AFEC'
            icon={statsIcon}
          />
          <StatsComponent
            title='Total Issues'
            stat={getTotalIssues() || 0}
            cardColor='#DE350B0D'
            titleColor='#DE350B'
            icon={issuesIcon}
          />
          <StatsComponent
            title='Ready to Publish'
            stat={1400}
            cardColor='#FF991F0D'
            titleColor='#FF991F'
            icon={readyIcon}
          />
          <StatsComponent
            title='Readiness Score'
            stat={60}
            cardColor='#00875A0D'
            titleColor='#00875A'
            icon={readinessIcon}
          />
        </div>
      </div>

      <div className={s.products__wrapper}>
        <div className={s.products__header}>
          <div className={s.products__header__title}>My Products</div>
          <input
            type='text'
            placeholder='Search...'
            value={searchText}
            onChange={onSearchTextChange}
            style={{
              border: 'none',
              padding: '5px 15px',
              width: '30%',
              backgroundColor: '#F3F6F8',
            }}
          />
          <div className={s.products__header__filters}>
            <div>
              {toggleToGridView ? (
                <UnorderedListOutlined
                  style={{ marginRight: 20 }}
                  onClick={() => setToggleToGridView(!toggleToGridView)}
                />
              ) : (
                <BorderBottomOutlined
                  style={{ marginRight: 20 }}
                  onClick={() => setToggleToGridView(!toggleToGridView)}
                />
              )}
            </div>
            <div>
              <Dropdown menu={{ items: createProductOptions }}>
                <Space>
                  Actions
                  <DownOutlined />
                </Space>
              </Dropdown>
            </div>
          </div>
          {/* <Text>Search</Text> */}
        </div>
        {toggleToGridView ? (
          <GalleryView products={products}></GalleryView>
        ) : (
          <div style={containerStyle}>
            <div
              style={gridStyle}
              className={'ag-theme-quartz'}>
              {isLoading && (
                <>
                  {' '}
                  <Spin
                    tip='Loading'
                    size='large'>
                    <div
                      className='content'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    />
                  </Spin>
                </>
              )}
              {!isLoading && (
                <AgGridCommonTable
                  rowData={products}
                  columnDefs={colDefs}
                  pagination={true}
                  rowSelection='multiple'
                  rowDragManaged={true}
                  rowDragEntireRow={true}
                  suppressRowClickSelection={true}
                  preventDefaultOnContextMenu={true}
                  contextMenu={displayContextMenu}
                  onGridReady={onGridReady}
                  inlineSave={inLineEditSaveSingle}
                  inlineSaveMulti={inLineEditSaveMulti}
                  columnReorederLocal='columnOrder'
                  gridOptions={gridOptions}
                />
              )}
              {contextMenuVisible && (
                <div
                  style={{
                    ...contextMenuStyles,
                    top: contextMenuPosition.y,
                    left: contextMenuPosition.x,
                  }}>
                  {contextMenuTemplate.map((item, index) => (
                    <div
                      className='context-menu-item'
                      key={index}
                      style={{ ...contextMenuItemStyles }}
                      onClick={item.action}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor =
                          contextMenuItemHoverStyles.backgroundColor;
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'inherit';
                      }}>
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {/* <Table
          style={{ padding: 20, fontSize: 18 }}
          dataSource={products}
          columns={columns}
          loading={isLoading}
        /> */}

        <SendToChannelPopup
          visible={sendToChannelVisible}
          onClose={closeSendToChannelPopup}
          arrayList={commonDropDownList}
          title={titleNmae}
          selectedRows={selectedRows}
          onChange={handleChange}
          onSubmit={handleConfirm}
        />
      </div>
    </div>
  );
};

// export default Products;
