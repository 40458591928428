import React, { createContext, useContext, useState } from 'react';
import { checkTokenAuthentication } from '../services/user';

// Step 1: Create a new context
const UserContext = createContext();

// Step 2: Create a UserProvider component
export const UserProvider = ({ children }) => {
  // Here, you can initialize the user state or fetch user data from an API
  const [user, setUser] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const checkAuthentication = async () => {
    try {
      const response = await checkTokenAuthentication()
      setIsAuthenticated(true);
    } catch (ex) {
      setIsAuthenticated(false);
      throw new Error('User not authenticated');
    }

  }


  const updateUser = (newUserData) => {
    // Update user data
    setUser((prevUser) => ({
      ...(prevUser || {}),
      ...newUserData,
    }));
  };

  // Step 3: Provide the user data and update function to the context
  const contextValue = {
    user,
    isAuthenticated,
    updateUser,
    checkAuthentication
  };

  // Step 4: Use the Provider component to wrap your app
  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

// Step 5: Create a custom hook to access the context
export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};
