import { Button, Card, Form, message, Input } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useUser } from '../context/user';
import BloomLogo from '../assets/bloom-local.png';
import './SetPassword.css';
import { updateUserPassword } from '../services/user';
import { useNavigate, useSearchParams } from 'react-router-dom';

const SetPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userEmail, setUserEmail] = useState({});

  useEffect(() => {
    const userEmailParam = searchParams.get('userEmail') || '';
    setUserEmail(userEmailParam);
  }, [searchParams]);

  // Make sure this effect runs after userEmail is set
  useEffect(() => {
    // Set form values after userEmail is updated
    form.setFieldsValue({
      email: userEmail,
    });
  }, [userEmail, form]);

  const onFinish = async () => {
    try {
      await form.validateFields();
      const { email, password } = form.getFieldsValue();
      const response = await updateUserPassword(email, password);
      if (response?.data?.token) {
        localStorage.setItem('token', response?.data?.token);
        navigate('/home');
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}>
      <img
        src={BloomLogo}
        height={150}
        width={150}
      />
      <Card
        style={{
          transition: 'box-shadow 0.3s',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          width: 500,
          height: 450,
          marginTop: 10,
        }}
        bordered
        hoverable
        bodyStyle={{ padding: 35 }}>
        <Form
          name='passwordForm'
          onFinish={onFinish}
          form={form}
          layout='vertical'
          initialValues={{
            email: userEmail,
          }}>
          <Form.Item
            name='email'
            label='Email'>
            <Input
              className='small-input'
              disabled
            />
          </Form.Item>
          <Form.Item
            label='Password'
            name='password'
            rules={[
              { required: true, message: 'Please input your password!' },
              { min: 6, message: 'Password must be at least 6 characters!' },
            ]}>
            <Input.Password placeholder='Enter password' />
          </Form.Item>

          <Form.Item
            label='Confirm Password'
            name='confirmPassword'
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please confirm your password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Passwords do not match!');
                },
              }),
            ]}>
            <Input.Password placeholder='Confirm password' />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={onFinish}
              style={{ width: '100%', height: 50 }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default SetPassword;
