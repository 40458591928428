import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import '../../src/index.css';


const TypeAheadFilter = (props) => {
    const [searchText, setSearchText] = useState('');
    const [suggestions, setSuggestions] = useState([]);


    useEffect(() => {
        setSearchText('');
        props.gridApi.setQuickFilter(null);
    }, [props.onSearchClick]);

    const onSearchTextChange = (event, { newValue }) => {
        setSearchText(newValue);
        if (props.gridApi) {
            if (newValue === '') {
                props.gridApi.setQuickFilter(null);
            }
        }
    };
    const onSuggestionSelected = (event, { suggestion }) => {
        setSearchText(suggestion.title);
        if (props.gridApi) {
            props.gridApi.setQuickFilter(suggestion.title);
        }
    };


    const onSuggestionsFetchRequested = ({ value }) => {
        const filteredSuggestions = props?.products?.filter(product => {
            for (const key in product) {
                if (Object.hasOwnProperty.call(product, key)) {
                    const propertyValue = product[key];
                    if (typeof propertyValue === 'string' && propertyValue.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return true;
                    }
                }
            }
            return false;
        });
        setSuggestions(filteredSuggestions);
    };


    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const getSuggestionValue = (suggestion) => {
        for (const key in suggestion) {
            if (Object.hasOwnProperty.call(suggestion, key)) {
                const value = suggestion[key];
                if (value && typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())) {
                    return value;
                }
            }
        }
    };
    const renderSuggestion = (suggestion) => (
        <>
            <div className='w-100' onClick={(event) => onSuggestionSelected(event, { suggestion })}>
                {Object.keys(suggestion).map((key, index) => (
                    key !== 'image_url' && key !== "description" && key !== 'updatedAt' && key !== 'createdAt' &&
                    key !== "user_id" && key !== 'locale' && key !== "product_type" && key !== "additional_info" && key !== "barcode" &&
                    key !== "category" && key !== "grad" && key !== "id" && key !== "images_url" && key !== "issues" &&
                    typeof suggestion[key] === 'string' &&
                    suggestion[key].toLowerCase().includes(searchText.toLowerCase()) && (
                        <div className='suggestion-text' key={index}>
                            <span>{suggestion[key]}</span>
                        </div>
                    )
                ))}
            </div>
        </>
    );
    const inputProps = {
        placeholder: 'Search with suggestion',
        value: searchText,
        onChange: onSearchTextChange
    };

    const renderSuggestionsContainer = ({ containerProps, children }) => {
        const containerStyle = suggestions && suggestions.length > 0 ? {
            position: 'absolute',
            width: '50%',
            maxHeight: '235px',
            overflowY: 'auto',
            background: '#fff',
            border: '1px solid #ccc',
            borderRadius: '5px',
            zIndex: 999
        } : {};
        return (
            <div
                {...containerProps}
                style={containerStyle}
            >
                {children}
            </div>
        );
    };

    return (

        <div style={{ width: '50%' }}>
            <Autosuggest
                className='poem'
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                renderSuggestionsContainer={renderSuggestionsContainer}
            />
        </div>
    );
};

export default TypeAheadFilter;