import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL || 'https://egrader.bloomlocal.ai/api'

export const syncData = async ({
  accessToken,
  storeUrl,
  apiVersion
}) => {
  const URL = `${API_URL}/auth/sync`
  try {
    const response = await axios.post(URL, {
      accessToken,
      storeUrl,
      apiVersion
    })
    return response
  } catch (ex) {
    console.error(ex)
    return ex
  }
}