import { Liquid } from '@ant-design/plots';
import React from 'react';

export const RedinessChart = ({ value = 0 }) => {
  const config = {
    percent: value / 100,
    outline: {
      border: 4,
      distance: 4,
    },
    wave: {
      length: 128,
    },
  };
  return <Liquid {...config} />;
};
