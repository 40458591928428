import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';

const { Option } = Select;

const SendToChannelPopup = (props) => {
    const [selectedChannel, setSelectedChannel] = useState<string | null>(null);

    const handleChannelChange = (event) => {
        setSelectedChannel(event)
        props.onChange(event);
    };

    const handleConfirm = () => {
        // console.log(props.selectedRows)
        setSelectedChannel(null);
        props.onSubmit();
        props.onClose();
    };

    useEffect(() => {
        setSelectedChannel(null);
    }, [props.onClose]);


    return (
        <Modal
            title={props.title}
            open={props.visible}
            onCancel={props.onClose}
            onOk={handleConfirm}
            destroyOnClose
        >
            <Select
                placeholder="Select Channel"
                style={{ width: '100%' }}
                onChange={handleChannelChange}
                value={selectedChannel}
            >
                {props.arrayList.map((channel) => (
                    <Option key={channel.key} value={channel.value}>
                        {channel.label}
                    </Option>
                ))}

            </Select>
        </Modal>
    );
};

export default SendToChannelPopup;
