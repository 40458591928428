import React, { useEffect, useState } from "react";
import s from "./product-detail.module.css";
import productIcon from "./../../assets/dummy_product.png";
import UnitedStatesIcon from "./../../assets/united-states.png";
import SaudiArabiaIcon from "./../../assets/saudi-arabia.png";
import NoImage from "../../assets/NoImage.jpeg";
import _ from "lodash";
import {
  ArrowUpOutlined,
  ArrowLeftOutlined,
  DownOutlined,
  FileImageOutlined,
  FontSizeOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
  AppstoreFilled,
  ColumnHeightOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import {
  CollapseProps,
  MenuProps,
  Typography,
  Checkbox,
  Spin,
  message,
  Popconfirm,
  Flex,
  Image,
} from "antd";
import {
  Tag,
  Button,
  Card,
  Collapse,
  Dropdown,
  Space,
  Tabs,
  ConfigProvider,
} from "antd";
import { Column } from "@ant-design/charts";
import { Gauge } from "@ant-design/plots";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchEnrichedProduct,
  fetchEnrichedProductImages,
  fetchProduct,
  getProductSeoGrading,
  updateProductWithEnrichment,
} from "../../services/product";
import { oldProductToNewProductSchemaMapping } from "../../utils/product";
import { SeoGrading } from "./SeoGrading";
import { RedinessChart } from "../../components/charts/Liquid";
import { enrichedDataModel, productDetailModel } from "@/models/product";

const { Text } = Typography;

const ColumnChart: React.FC<any> = ({ data }) => {
  // const data = [
  //   { platform: 'Shopify', value: 60 },
  //   { platform: 'Amazon', value: 30 },
  //   { platform: 'WooComm', value: 85 },
  //   { platform: 'Etsy', value: 40 },
  //   { platform: 'Ebay', value: 80 },
  // ];

  const props = {
    data,
    height: 180,
    xField: "platform",
    yField: "value",
    style: {
      fill: "#F3F6F8",
    },
  };

  return <Column {...props} />;
};

const ReadinessGauge = ({ value }) => {
  return (
    <div style={{ height: 150 }}>
      <RedinessChart value={value} />
    </div>
  );
};

const ChannelGauge = () => {
  const config = {
    width: 240,
    height: 220,
    autoFit: true,
    data: {
      target: 70,
      total: 100,
      name: "score",
    },
    legend: false,
  };
  return <Gauge {...config} />;
};

const text = `
  The details of the expanded tab goes here...
`;

const languages = [
  {
    language: "English",
    icon: UnitedStatesIcon,
    locale: "en",
  },
  {
    language: "Arabic",
    icon: SaudiArabiaIcon,
    locale: "ar",
  },
  // {
  //   language: 'French',
  //   icon: FranceIcon,
  // },
  // {
  //   language: 'Add Language',
  //   icon: GlobeIcon,
  // },
];

const ImageComponent: React.FC<{
  image?: string;
  isSelected?: boolean;
  onClick?: () => void;
}> = ({ image, onClick, isSelected }) => {
  const imageStyle = {
    border: isSelected ? "2px solid blue" : "2px solid transparent", // Apply border if selected
    cursor: "pointer",
  };
  return (
    <Card
      hoverable
      onClick={onClick}
      style={{ ...imageStyle }}
      bodyStyle={{
        padding: 0,
        width: 160,
        height: 160,
        background: "#fff",
        borderRadius: 8,
      }}
    >
      <div style={{ marginTop: 20 }}>
        <Image
          src={image || productIcon}
          alt="Product"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 8,
          }}
        ></Image>
      </div>
    </Card>
  );
};

const StatsComponent: React.FC<{
  title: string;
  subtitle: string;
  stat: number;
  color: string;
}> = ({ title, subtitle, stat, color }) => {
  return (
    <Card
      hoverable
      bodyStyle={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
      style={{
        width: 380,
        height: 246,
        background: color,
        borderRadius: 20,
      }}
    >
      <div style={{ margin: 10 }}>
        {/* <img src={statsIcon} alt="Stats"></img> */}
      </div>
      <div style={{ fontSize: 16 }}>{title}</div>
      <div style={{ fontSize: 60 }}>{stat || 0}</div>
      <div
        style={{ margin: 0, fontSize: 14, color: "rgba(143, 146, 161, 0.80)" }}
      >
        {subtitle || 0}
      </div>
      <div
        style={{
          marginTop: 10,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ color: "#4B9F47" }}>
          <ArrowUpOutlined />
          105.23 %
        </div>
        <Button>See Issues</Button>
      </div>
    </Card>
  );
};

export const ProductDetail = () => {
  const { id: productId } = useParams();
  const [productDetail, setProductDetail] = useState<productDetailModel>({});
  const [isEnrichDataLoading, setIsEnrichDataLoading] = useState(true);
  const [enrichedData, setEnrichedData] = useState<enrichedDataModel>({});
  const [enrichedProductResponse, setEnrichedProductResponse] = useState();
  const navigate = useNavigate();
  const [productColumnChartData, setProductColumnChartData] = useState([]);
  const [enrichedProductColumnChartData, setEnrichedProductColumnChartData] =
    useState([]);
  const [enrichedImages, setEnrichedImages] = useState([]);
  const [selectedEnrichedLocale, setSelectedEnrichedLocale] = useState("en");
  const [optedAttributes, setOptedAttributes] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [openSeoGradingModal, setOpenSeoGradingModal] = useState(false);

  const toggleImageSelection = (imageSrc) => {
    // Check if the image is already selected
    const isSelected = selectedImages.includes(imageSrc);

    // Update the selected images state
    if (isSelected) {
      setSelectedImages((prevSelected) =>
        prevSelected.filter((selected) => selected !== imageSrc)
      );
    } else {
      setSelectedImages((prevSelected) => [...prevSelected, imageSrc]);
    }
  };

  const transformUnderscoreToReadable = (inputString) => {
    let stringWithSpaces = inputString.replace(/_/g, " ");

    // Use lodash's startCase to capitalize the first letter of each word
    let capitalizedStr = _.startCase(stringWithSpaces);
    return capitalizedStr;
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          <FileImageOutlined /> IMAGES
        </div>
      ),
      children: (
        <div>
          <div style={{ display: "flex", gap: "8px", overflow: "scroll" }}>
            {productDetail?.images_url?.length ? (
              productDetail?.images_url?.map((productImgSrc: string) => {
                return <ImageComponent image={productImgSrc} />;
              })
            ) : productDetail.image_url ? (
              <ImageComponent image={productDetail.image_url} />
            ) : (
              <ImageComponent image={NoImage} />
            )}
          </div>
          <div>
            <div style={{ fontSize: 12, marginTop: 10, marginBottom: 10 }}>
              Identified Issues
            </div>
            <div>
              <Tag bordered={false} color="volcano">
                Poor quality
              </Tag>
              <Tag bordered={false} color="volcano">
                Too few product pictures
              </Tag>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          <FontSizeOutlined /> PRODUCT TITLE
        </div>
      ),
      children: <Text>{productDetail?.title}</Text>,
    },
    {
      key: "3",
      label: (
        <div>
          <FileTextOutlined /> PRODUCT DESCRIPTIONS
        </div>
      ),
      children: <p>{productDetail?.description}</p>,
    },
    {
      key: "5",
      label: (
        <div>
          <AppstoreFilled /> PRODUCT BRAND
        </div>
      ),
      children: <p>{productDetail?.brand || "NA"}</p>,
    },
  ];

  const enrichedItems: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div style={{ display: "flex" }}>
          <div style={{ marginLeft: 10 }}>ENRICHED IMAGES</div>
        </div>
      ),
      children: (
        <div>
          <div style={{ display: "flex", gap: "8px", overflow: "scroll" }}>
            {Object.keys(enrichedData)?.length &&
            enrichedImages &&
            enrichedImages.length ? (
              enrichedImages.map((image: any) => {
                return (
                  <ImageComponent
                    image={image.link}
                    onClick={() => toggleImageSelection(image?.link)}
                    isSelected={selectedImages.includes(image?.link)}
                  />
                );
              })
            ) : (
              <ImageComponent image={NoImage} />
            )}
          </div>
          <div>
            <div style={{ fontSize: 12, marginTop: 10, marginBottom: 10 }}>
              Identified Issues
            </div>
            <div>
              <Tag bordered={false} color="green">
                New images better resolution
              </Tag>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div style={{ display: "flex" }}>
          <div>
            <Checkbox
              onChange={(e) =>
                handleAttributeSelection("title", e.target.checked)
              }
            />
          </div>
          <div style={{ marginLeft: 10 }}>
            <FontSizeOutlined style={{ marginRight: 10 }} />
            ENRICHED PRODUCT TITLE
          </div>
        </div>
      ),
      children: <Text>{enrichedData?.title}</Text>,
    },
    {
      key: "3",
      label: (
        <div style={{ display: "flex" }}>
          <div>
            <Checkbox
              onChange={(e) =>
                handleAttributeSelection("description", e.target.checked)
              }
            />
          </div>
          <div style={{ marginLeft: 10 }}>
            <FileTextOutlined style={{ marginRight: 10 }} />
            ENRICHED PRODUCT DESCRIPTION
          </div>
        </div>
      ),
      children: <p>{enrichedData?.description}</p>,
    },
    {
      key: "4",
      label: (
        <div style={{ display: "flex" }}>
          <div>
            <Checkbox onChange={(e) => {}} />
          </div>
          <div style={{ marginLeft: 10 }}>
            <CheckCircleOutlined style={{ marginRight: 10 }} />
            PRODUCT FEATURES
          </div>
        </div>
      ),
      children: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {enrichedData &&
            Object.keys(enrichedData).length &&
            Object.keys(enrichedData).map((key) => {
              const additionalKey = [
                "color",
                "age_group",
                "size",
                "weight",
                "material",
                "pattern",
                "features",
                "google_product_category",
              ];
              if (additionalKey.includes(key)) {
                return (
                  <div style={{ display: "flex" }}>
                    <Checkbox />
                    <div style={{ marginLeft: 10 }}>
                      {`${transformUnderscoreToReadable(key)} - ${
                        enrichedData[key]
                      }`}
                    </div>
                  </div>
                );
              }
            })}
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div style={{ display: "flex" }}>
          <div>
            <Checkbox
              onChange={(e) =>
                handleAttributeSelection("brand", e.target.checked)
              }
            />
          </div>
          <div style={{ marginLeft: 10 }}>
            <AppstoreFilled style={{ marginRight: 10 }} />
            ENRICHED PRODUCT BRAND
          </div>
        </div>
      ),
      children: <p>{enrichedData?.brand}</p>,
    },
    {
      key: "6",
      label: (
        <div style={{ display: "flex" }}>
          <div>
            <Checkbox
              onChange={(e) =>
                handleAttributeSelection("gtin", e.target.checked)
              }
            />
          </div>
          <div style={{ marginLeft: 10 }}>
            <AppstoreFilled style={{ marginRight: 10 }} />
            BARCODE / GTIN
          </div>
        </div>
      ),
      children: <p>{enrichedData?.gtin}</p>,
    },
    {
      key: "7",
      label: (
        <div style={{ display: "flex" }}>
          <div style={{ marginLeft: 10 }}>
            <InfoCircleFilled style={{ marginRight: 10 }} />
            Additional Informations
          </div>
        </div>
      ),
      children: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {enrichedData &&
            Object.keys(enrichedData).length &&
            Object.keys(enrichedData).map((key) => {
              const additionalKey = [
                "tax",
                "return_policy",
                "shipping_weight",
              ];
              if (additionalKey.includes(key)) {
                return (
                  <div style={{ display: "flex" }}>
                    <Checkbox />
                    <div style={{ marginLeft: 10 }}>
                      {`${transformUnderscoreToReadable(key)} - ${
                        enrichedData[key]
                      }`}
                    </div>
                  </div>
                );
              }
            })}
        </div>
      ),
    },
  ];

  const handleAttributeSelection = (attributeName, isSelected) => {
    const transformedKey =
      oldProductToNewProductSchemaMapping[attributeName] || attributeName;
    if (!optedAttributes[transformedKey] && isSelected) {
      setOptedAttributes({
        ...optedAttributes,
        [transformedKey]: enrichedData[attributeName] || "",
      });
    } else if (optedAttributes[transformedKey] && !isSelected) {
      const temp: any = { ...optedAttributes };
      delete temp[transformedKey];
      setOptedAttributes({
        ...temp,
      });
    }
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      const response = await fetchProduct({ id: productId });
      if (response && response.data) {
        setProductDetail(response.data);
        const scores = response.data.scores || [];
        const chartlist: any = [];
        scores.forEach((score) => {
          const data: any = {
            platform: score.channel,
            value: score.redinessScore,
          };
          chartlist.push(data);
        });
        // Remove this later
        chartlist.push(
          { platform: "Amazon", value: 0 },
          { platform: "Ebay", value: 0 }
        );
        setProductColumnChartData(chartlist);
      }
    };
    const fetchEnrichedProductDetails = async () => {
      try {
        const response = await fetchEnrichedProduct({ id: productId });
        if (response && response.data && response.data.enrichedData) {
          const data =
            Array.isArray(response.data.enrichedData) &&
            response.data.enrichedData[0]
              ? response.data.enrichedData[0]
              : response.data.enrichedData;
          if (data) {
            setEnrichedProductResponse(response.data.enrichedData);
            setEnrichedData({ ...data, scores: response.data?.scores || [] });
            const scores = response.data.scores || [];
            const chartlist: any = [];
            scores.forEach((score) => {
              const data: any = {
                platform: score.channel,
                value: score.redinessScore,
              };
              chartlist.push(data);
            });
            // Remove this later
            chartlist.push(
              { platform: "Amazon", value: 0 },
              { platform: "Ebay", value: 0 }
            );
            setEnrichedProductColumnChartData(chartlist);
            setIsEnrichDataLoading(false);
          }
        }
      } catch (ex) {
        message.error(ex?.response?.data);
        setIsEnrichDataLoading(false);
      }
    };
    const fetchEnrichedImages = async () => {
      const response: any = await fetchEnrichedProductImages({ id: productId });
      if (response.data && response.data && response.data.length) {
        setEnrichedImages(response.data);
      }
    };
    if (productId) {
      fetchProductDetails();
      fetchEnrichedProductDetails();
      fetchEnrichedImages();
    }
  }, []);

  useEffect(() => {
    if (selectedEnrichedLocale) {
      if (selectedEnrichedLocale === "ar") {
        const data =
          (enrichedProductResponse && enrichedProductResponse?.[1]) || {};
        console.log("==== 583 ===", data);
        setEnrichedData({
          ...enrichedData,
          ...data,
        });
      } else {
        const data =
          (enrichedProductResponse && enrichedProductResponse?.[0]) || {};
        setEnrichedData({
          ...enrichedData,
          ...data,
        });
      }
    }
  }, [selectedEnrichedLocale]);

  const applyFixes = async () => {
    let transformedData = {};

    transformedData = {
      ...productDetail,
      ...(optedAttributes || {}),
      images_url: selectedImages || productDetail?.images_url || [],
      image_url:
        (selectedImages && selectedImages[0]) || productDetail?.image_url,

      locale: selectedEnrichedLocale || "en",
      product_id: productDetail.id,
    };
    delete transformedData["id"];
    setIsLoading(true);
    try {
      await updateProductWithEnrichment({
        id: productId,
        data: transformedData,
      });
      navigate("/products");
      setIsLoading(false);
    } catch (ex) {
      console.error(ex);
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <SeoGrading
        productDetail={productDetail}
        isOpen={openSeoGradingModal}
        setIsOpen={setOpenSeoGradingModal}
      />
      <div className={s.page}>
        <div className={s.welcome__header__wrapper}>
          <div className={s.welcome__header}>
            <div className={s.welcome__header__info}>
              <div className={s.welcome__header__info_title}>
                <ArrowLeftOutlined
                  onClick={() => {
                    navigate("/products");
                  }}
                />
              </div>
            </div>
            <div className={s.welcome__header__action}>
              {/* <Dropdown menu={{ items: [] }}> */}
              <Space size={"large"}>
                <img
                  src={productDetail?.image_url || productIcon}
                  alt="Product"
                  style={{
                    width: 24,
                    height: 24,
                    objectFit: "cover",
                    borderRadius: 4,
                  }}
                ></img>
                <div className={s.welcome__header__action_title}>
                  {productDetail.title}
                </div>
                <div className={s.welcome__header__action_subtitle}>
                  Product ID: <span>{productDetail.external_id}</span>
                </div>
                <DownOutlined />
              </Space>
              {/* </Dropdown> */}
              <Button onClick={() => setOpenSeoGradingModal(true)}>
                Show Advance Profiling
              </Button>
            </div>
          </div>
        </div>

        <div className={s.page__wrapper}>
          <div className={s.page__left}>
            <div className={s.page__left__header}>
              {/* <div className={s.page__left_title}>Current State</div> */}
              <Tabs
                defaultActiveKey="0"
                type="card"
                size="large"
                tabBarExtraContent={{
                  left: <div className={s.page__left_title}>Current State</div>,
                }}
                items={new Array(1).fill(null).map((_, i) => {
                  const id = String(i);
                  return {
                    label: (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                          gap: 6,
                        }}
                      >
                        <img
                          src={languages[id]?.icon}
                          alt="Product"
                          style={{
                            width: 12,
                            height: 12,
                            objectFit: "cover",
                            // borderRadius: 8,
                          }}
                        ></img>
                        {languages[id].language}
                      </div>
                    ),
                    key: id,
                    children: ``,
                  };
                })}
              />
            </div>

            <div className={s.page__left__body}>
              <div className={s.readiness__wrapper}>
                <div className={s.readiness__left}>
                  <div className={s.readiness__left__graph_title}>Score</div>
                  {productDetail?.scores ? (
                    <ReadinessGauge
                      value={productDetail?.scores[0].redinessScore}
                    ></ReadinessGauge>
                  ) : undefined}
                  <div
                    className={s.readiness__left__graph_caption}
                    style={{ marginTop: 0 }}
                  >
                    Readiness across channels
                  </div>
                </div>
                <div className={s.readiness__right}>
                  <div className={s.readiness__right__header}>
                    <div className={s.readiness__right__header_title}>
                      Readiness for Channels
                    </div>
                  </div>
                  <div>
                    {productColumnChartData ? (
                      <ColumnChart data={productColumnChartData}></ColumnChart>
                    ) : undefined}
                  </div>
                </div>
              </div>

              <ConfigProvider
                theme={{
                  components: {
                    Collapse: {
                      headerBg: "#fff",
                    },
                  },
                }}
              >
                <Collapse
                  accordion
                  items={items}
                  defaultActiveKey={1}
                  expandIconPosition="end"
                  bordered={false}
                />
              </ConfigProvider>
            </div>
          </div>
          <div className={s.page__right}>
            <div className={s.page__right__header}>
              {/* <div className={s.page__right_title}>Optimised Result</div> */}
              <Tabs
                defaultActiveKey="0"
                type="card"
                size="large"
                onTabClick={(value) => {
                  const locale = languages.filter(
                    (language) => language.locale === value
                  );
                  setSelectedEnrichedLocale(locale[0].locale);
                }}
                tabBarExtraContent={{
                  left: (
                    <div className={s.page__right_title}>Optimised Result</div>
                  ),
                }}
                // tabBarStyle={{ background: "red" }}
                items={languages?.map((language, index) => {
                  return {
                    label: (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                          gap: 6,
                        }}
                      >
                        <img
                          src={language.icon}
                          alt="Language"
                          style={{
                            width: 12,
                            height: 12,
                            objectFit: "cover",
                          }}
                        ></img>
                        {language.language}
                      </div>
                    ),
                    key: language.locale,
                    children: ``,
                  };
                })}
              />
            </div>
            {isEnrichDataLoading ? (
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            ) : Object.keys(enrichedData)?.length ? (
              <div className={s.page__right__body}>
                <div className={s.readiness__wrapper}>
                  <div className={s.readiness__left}>
                    <div className={s.readiness__left__graph_title}>Score</div>
                    {enrichedData?.scores && enrichedData?.scores.length ? (
                      <ReadinessGauge
                        value={enrichedData?.scores[0].redinessScore}
                      ></ReadinessGauge>
                    ) : undefined}
                    <div
                      className={s.readiness__left__graph_caption}
                      style={{ marginTop: 0 }}
                    >
                      Readiness across channels
                    </div>
                  </div>
                  <div className={s.readiness__right}>
                    <div className={s.readiness__right__header}>
                      <div className={s.readiness__right__header_title}>
                        Readiness for Channels
                      </div>
                    </div>
                    <div>
                      {enrichedProductColumnChartData ? (
                        <ColumnChart
                          data={enrichedProductColumnChartData}
                        ></ColumnChart>
                      ) : undefined}
                    </div>
                  </div>
                </div>
                <ConfigProvider
                  theme={{
                    components: {
                      Collapse: {
                        headerBg: "transparent",
                      },
                    },
                  }}
                >
                  <Collapse
                    items={enrichedItems}
                    defaultActiveKey={"1"}
                    expandIconPosition="end"
                    bordered={false}
                  />
                </ConfigProvider>
              </div>
            ) : (
              <div
                style={{
                  margin: 50,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p style={{ color: "red" }}>
                  Insufficient data to proceed with AI generation
                </p>
              </div>
            )}

            <div className={s.page__right_footer}>
              {/* <div>Save Recommendations</div> */}
              <Popconfirm
                title="Update existing product with Enrichment"
                description="Are you sure to you want to update this product?"
                okText="Yes"
                cancelText="No"
                okButtonProps={{
                  style: { background: "blue" },
                }}
                onConfirm={applyFixes}
              >
                <Button type="primary" style={{ background: "#00875A" }}>
                  Update Existing Product with Enrichement
                </Button>
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
