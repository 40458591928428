import React from 'react';
import { Badge, Card, Image, Typography } from 'antd';
import NoImage from '../../assets/NoImage.jpeg';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

export const GalleryView = ({ products }: { products }) => {
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-4 gap-5 px-5">
      {products.map((product) => (
        <div className="col-span-1">
          <Card
            className="overflow-hidden"
            onClick={() => navigate(`/product/${product.id}`)}
            hoverable
            style={{ height: "100%" }}
            cover={
              <>
                <Badge
                  count={Math.floor((product.grad / 11) * 100)}
                  style={{
                    position: 'absolute',
                    top: 15,
                    right: 15,
                    backgroundColor: 'orange',
                    color: 'white',
                    fontWeight: 'bold',
                  }}>
                </Badge>
                <Image
                  className="aspect-square object-contain"
                  alt="product"
                  src={product.image_url || NoImage}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </>
            }>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text strong>{product.title}</Text>
              <Text>{truncateDescription(product.description, 20)}</Text>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

const truncateDescription = (description: string, maxLength: number) => {
  if (description && description?.length > maxLength) {
    return `${description.substring(0, maxLength)}...`;
  }
  return description || 'This is sample description';
};
