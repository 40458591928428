import React from 'react';
import { Column } from '@ant-design/charts';
import shopifyIcon from './../assets/logos_shopify.svg';
import productIcon from './../assets/dummy_product.png';
import storeIcon from './../assets/checker-logo.png';
import GMC_Icon from './../assets/GMC_Icon.png';
import whatsAppIcon from '../assets/whatsapp-business.svg';
import ebayIcon from '../assets/ebay_icon-icons.com_62730.png';
import amazonSaudiIcon from '../assets/amazon_saudi.png';
import woocommernceIcon from '../assets/woocommerce.jpg';
import bingShoppingIcon from '../assets/bing-shopping.jpeg';
import etsyIcon from '../assets/etsy.png';
import aliExpressIcon from '../assets/ali_express.png';
import { Badge, Col, Image, Row, Typography } from 'antd';

const brandLogoMapping = {
  Shopify: shopifyIcon,
  Ebay: ebayIcon,
  WooCommerce: woocommernceIcon,
  Etsy: etsyIcon,
  'Amazon Saudi': amazonSaudiIcon,
  'Meta Commerce': whatsAppIcon,
  'Bing Shopping': bingShoppingIcon,
  Aliexpress: aliExpressIcon,
};
const { Title, Text } = Typography;

const DemoBarChart = () => {
  const data = [
    {
      type: 'Ebay',
      sales: 80,
    },
    {
      type: 'Amazon Saudi',
      sales: 25,
    },
    {
      type: 'WooCommerce',
      sales: 85,
    },
    {
      type: 'Etsy',
      sales: 27,
    },
    {
      type: 'Bing Shopping',
      sales: 40,
    },
    {
      type: 'Shopify',
      sales: 60,
    },
    {
      type: 'Aliexpress',
      sales: 50,
    },
    {
      type: 'Meta Commerce',
      sales: 45,
    },
  ];
  const config = {
    data,
    xField: 'type',
    yField: 'sales',
    label: {
      text: (originData) => {
        const val = parseFloat(originData.value);
        if (val < 0.05) {
          return (val * 100).toFixed(1) + '%';
        }
        return '';
      },
      offset: 10,
    },
    interaction: {
      tooltip: {
        render: (e, { title, items }) => (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Image
                height={30}
                width={30}
                src={brandLogoMapping[title]}
              />
              <Title
                style={{ marginTop: 10, marginLeft: 10 }}
                level={5}>
                {title}
              </Title>
            </div>
            <div>
              <Badge color='green'></Badge>
              <Text style={{ marginLeft: 5, marginRight: 5, color: 'gray' }}>
                <Text style={{ fontWeight: 800 }}>70</Text> Products Ready
              </Text>
              <Text style={{ marginLeft: 5, marginRight: 5, fontWeight: 800 }}>
                55%
              </Text>
            </div>
            <div>
              <Badge color='red'></Badge>
              <Text style={{ marginLeft: 5, marginRight: 5, color: 'gray' }}>
                <Text style={{ fontWeight: 800 }}>124</Text> Issues / 60
                Prodcuts
              </Text>
            </div>
          </>
        ),
      },
    },
    legend: false,
  };
  return <Column {...config} />;
};

export default DemoBarChart;
